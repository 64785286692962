// src/context/LanguageContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LanguageContextProps {
  language: string;
  toggleLanguage: () => void;
  buttonText: string;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

const LOCAL_STORAGE_KEY = 'Lang';
const LANGUAGES = ['en', 'ar']; // Define your supported languages here


export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_KEY) || i18n.language;
  const [language, setLanguage] = useState<string>(storedLanguage);
  const [buttonText, setButtonText] = useState<string>('AR');

  const toggleLanguage = () => {
    const currentLanguageIndex = LANGUAGES.indexOf(language);
    const nextLanguageIndex = (currentLanguageIndex + 1) % LANGUAGES.length;
    const nextLanguage = LANGUAGES[nextLanguageIndex];

    i18n.changeLanguage(nextLanguage);
    localStorage.setItem(LOCAL_STORAGE_KEY, nextLanguage);
    setLanguage(nextLanguage);
    updateButtonText(nextLanguage);
  };

  const updateButtonText = (lang: string) => {
    if (lang === 'en') {
      setButtonText('AR');
    } else if (lang === 'ar') {
      setButtonText('EN');
    }
  };

  useEffect(() => {
    document.body.dir = i18n.dir();
    updateButtonText(language);
  }, [language, i18n]);

  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, buttonText }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
